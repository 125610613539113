import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { userStore } from "@/store/user";
import { getLogoutUrl } from "./utils/auth";

import { storeToRefs } from 'pinia';
import { ref } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const userStoreUse = userStore();
    const {
      userInfo
    } = storeToRefs(userStoreUse);
    const isOpenDlg = ref(false);
    const router = useRouter();
    document.addEventListener("visibilitychange", function (e, t) {
      var _userInfo$value;
      const baseData = localStorage.getItem("userInfo") || "";
      const baseParseData = baseData ? JSON.parse(baseData) : {};
      const baseUserInfo = (baseParseData === null || baseParseData === void 0 ? void 0 : baseParseData.teacher) || {};
      const visibilityState = document.visibilityState === "visible";
      // 路由白名单
      const whiteList = ["/login", "/auth", "/"];
      if (whiteList.includes(router.currentRoute.value.path)) {
        return;
      }
      if (((_userInfo$value = userInfo.value) === null || _userInfo$value === void 0 ? void 0 : _userInfo$value.id) != (baseUserInfo === null || baseUserInfo === void 0 ? void 0 : baseUserInfo.id) && !isOpenDlg.value && visibilityState) {
        isOpenDlg.value = true;
        ElMessageBox.confirm("当前页面下有其他账号在不同设备、浏览器或选项卡发生了登录操作。请刷新以同步至最新登录账号，或切换其他账号登录。", "登录账号发生变更", {
          confirmButtonText: "登录其他账号",
          // cancelButtonText: "刷新页面",
          showClose: false,
          confirmButtonClass: "botton_My",
          // cancelButtonClass: "botton_My",
          customClass: "message_Box",
          autofocus: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          showCancelButton: false
          // type: "warning",
        }).then(() => {
          isOpenDlg.value = false;
          let logoutUrl = getLogoutUrl();
          location.href = logoutUrl;
        }).catch(() => {
          isOpenDlg.value = false;
          // window.location.reload();
        });
      }
    });

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }
};