import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/element/index.scss'

createApp(App).use(ElementPlus, {
    locale: zhCn,
}).use(router).use(pinia).mount('#app')
