/**
 * 接口域名的管理
 * @param {String} apiServer [api服务器]
 * @param {String} SchoolAdminServer [教师服务器]
 */
const baseLsit = [
  {
    // apiServer: "http://10.0.30.117:8430", //开发服务器 (棋哥)
    // apiServer: "http://10.0.30.183:8430", //开发服务器 (棋哥)

    apiServer: "http://10.0.30.204:30700", //开发服务器 （老伍）
    // SchoolAdminServer: "http://10.0.30.150:8420", //教师开发服务器
    // SchoolAdminServer: 'http://10.0.30.109:8420', //教师开发服务器
    SchoolAdminServer: "http://10.0.30.204:32413", //教师开发服务器
    treeServer: "https://xzzj.zjjy.xyz/edu-admin-api-test1", //正式服务器
    uploadServer: "https://api.ebag-test.readboy.com/education-cube",
    csServer: 'https://api.ebag-test.readboy.com/wps'
  },
  {
    apiServer: "http://10.0.30.150:8430", //开发服务器 （琪哥）
    SchoolAdminServer: "http://10.0.30.150:8420", //教师开发服务器,
    uploadServer: "https://api.ebag-test.readboy.com/education-cube",
    csServer: 'https://api.ebag-test.readboy.com/wps'
  },
  {
    apiServer: "https://problem-server.zhuji-competitions.boran-tech.com", //测试服务器 2
    SchoolAdminServer: "https://xzzj.zjjy.xyz/teaching-staff-server", //正式服务器
    // uploadServer: "https://xzzj.zjjy.xyz/zhuji-education-cube-api",
    uploadServer: "https://api.ebag-test.readboy.com/education-cube",
    treeServer: "https://xzzj.zjjy.xyz/edu-admin-api", //正式服务器
    csServer: 'https://api.ebag-test.readboy.com/wps'
  },
  {
    apiServer: "https://xzzj.zjjy.xyz/problem-server", //正式服务器 3
    SchoolAdminServer: "https://xzzj.zjjy.xyz/teaching-staff-server", //正式服务器
    // uploadServer: "https://xzzj.zjjy.xyz/zhuji-education-cube-api",
    uploadServer: "https://api.ebag-test.readboy.com/education-cube",
    treeServer: "https://xzzj.zjjy.xyz/edu-admin-api", //正式服务器
    csServer: 'https://api.ebag-test.readboy.com/wps'
  },
  {
    apiServer: "http://xx.xx.xx.xx", //开发服务器(本地202环境) 4
    SchoolAdminServer: "http://xx.xx.xx", //开发服务器
    csServer: 'https://api.ebag-test.readboy.com/wps'
  },
];
const ServerNumber = process.env.VUE_APP_SERVER_ID
  ? process.env.VUE_APP_SERVER_ID
  : 0;
// const ServerNumber = 1;
// 后台登录地址
const loginPathList = [
  {
    host: "http://localhost:8081",
    path: "/",
  },
  {
    host: "http://localhost:8081",
    path: "/",
  },
  {
    host: "https://problem-web.zhuji-competitions.boran-tech.com",
    path: "/",
  },
  {
    host: "https://xzzj.zjjy.xyz",
    path: "/problem-competitions/",
  },
  {
    host: "http://xx.xx.xx.xx",
    path: "/",
  },
];
//下载导出模板
const fileTemplate = [
  {
    schoolimport:
      "http://10.0.30.150:8430/activityupload/template/学校导入模板.xlsx",
    import: "http://10.0.30.150:8430/problemupload/导入模板.xlsx",
  },
  {
    schoolimport:
      "http://10.0.30.150:8430/activityupload/template/学校导入模板.xlsx",
    import: "http://10.0.30.150:8430/problemupload/导入模板.xlsx",
  },
  {
    schoolimport:
      "https://xzzj.zjjy.xyz/activity-server/activityupload/res/excel/schoolimport.xlsx",
    import:
      "https://xzzj.zjjy.xyz/activity-server/activityupload/res/excel/import.xlsx",
  },
  {
    schoolimport:
      "https://xzzj.zjjy.xyz/activity-server/activityupload/res/excel/schoolimport.xlsx",
    import:
      "https://xzzj.zjjy.xyz/activity-server/activityupload/res/excel/import.xlsx",
  },
  {
    schoolimport:
      "http://10.0.30.150:8430/activityupload/template/学校导入模板.xlsx",
    import: "http://10.0.30.150:8430/problemupload/导入模板.xlsx",
  },
];
export const loginPath = loginPathList[ServerNumber];
export const fileNew = fileTemplate[ServerNumber];
const baseUrl = baseLsit[ServerNumber];
export default baseUrl;
