// 在http.js中引入axios
import axios from 'axios' // 引入axios
import { ElMessage, ElLoading } from 'element-plus'
import { getToken } from '@/utils/auth'
import { userStore } from '@/store/user'
import baseUrl from '@/utils/http/base.js'
import { loginPath } from '@/utils/http/base.js'
let loading //定义loading变量

function startLoading() {
  //使用Element loading-start 方法
  loading = ElLoading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
function endLoading() {
  //使用Element loading-close 方法
  loading.close()
}
//showFullScreenLoading() 与 tryHideFullScreenLoading() 目的是合并同一页面多个请求触发loading

let needLoadingRequestCount = 0 //声明一个变量

let showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) {
    //当等于0时证明第一次请求 这时开启loading
    startLoading()
  }
  needLoadingRequestCount++ //全局变量值++
}

let tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return //小于等于0 证明没有开启loading 此时return
  needLoadingRequestCount-- //正常响应后 全局变量 --
  if (needLoadingRequestCount === 0) {
    //等于0 时证明全部加载完毕 此时结束loading 加载
    endLoading()
  }
}
// 创建axios实例
var instance = axios.create({
  // baseURL: baseUrl.apiServer,
  // baseURL: '',
  timeout: 1000 * 12,
})
/****** request拦截器 ==> 对请求参数做处理 ******/
instance.interceptors.request.use(
  (config) => {
    const { isNeedToken = true, isOther, isUpload } = config
    // console.log(isNeedToken);
    if (isNeedToken) {
      // console.log(getToken());
      const { accessToken: token } = getToken() || {}
      // const now = new Date().getTime();
      //   const expired = parseInt("" + data.expires) - now <= 0;
      // if (expired) {
      //   if (!PureHttp.isRefreshing) {
      //     PureHttp.isRefreshing = true;
      //     // token过期刷新
      //     // useUserStoreHook()
      //     //   .handRefreshToken({ refreshToken: data.refreshToken })
      //     //   .then(res => {
      //     //     const token = res.data.accessToken;
      //     //     config.headers["Authorization"] = formatToken(token);
      //     //     PureHttp.requests.forEach(cb => cb(token));
      //     //     PureHttp.requests = [];
      //     //   })
      //     //   .finally(() => {
      //     //     PureHttp.isRefreshing = false;
      //     //   });
      //   }
      // resolve(PureHttp.retryOriginalRequest(config));
      // console.log(token);
      if (isUpload) {
        let uploadToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwIiwib3JpZ2luIjowLCJpZCI6IjAxMjAyODc5MTc3NTQwMDE1NzU5IiwidXNlck5hbWUiOiJ5bGowMzgyMTYiLCJuaWNrTmFtZSI6IuS_nueBteadsCIsImJ1ZmZlclRpbWUiOjg2NDAwLCJleHAiOjE2OTIyNTc4NzYsImlzcyI6InJkIiwibmJmIjoxNjkxNjUyMDc2fQ.8FDoCUJb5cYv3y9RVTuBXdRHCQ5yyZ9gwkTAREdW6wo"
        config.headers["x-auth-token"] = uploadToken
      } else {
        config.headers.Authorization = `Bearer ${token}`
      }


    }
    if (config.url.indexOf('/common/file/download') != -1) {
      config['responseType'] = 'blob'
    }
    if (isOther) {
      const { dslToken } = JSON.parse(localStorage.getItem('userInfo')) || ''
      config.headers.Authorization = `Bearer ${dslToken}`
    }

    // console.log(config);
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
let isShowRes401Tips = false
/****** response拦截器 ==> 对响应做处理 ******/
instance.interceptors.response.use(
  (response) => {
    //成功请求到数据
    if (
      response.config.url.indexOf('/common/file/upload') == -1 &&
      response.config.url.indexOf('/common/file/check') == -1 &&
      response.config.url.indexOf('/common/unpack') == -1 &&
      response.config.url.indexOf('/back/asyncTask/find') == -1 &&
      response.config.url.indexOf('/common/cos/file/generateUploadId') == -1 &&
      response.config.url.indexOf('/common/cos/file/chunkUpload') == -1
    ) {
      if (!response.config.notLoadding) tryHideFullScreenLoading()
    }
    if (response.data.code === 400 || response.data.code === 401 || response.data.code === 10013) {
      //未经授权时，统一处理
      if (!isShowRes401Tips) {
        isShowRes401Tips = true
        ElMessage({
          message: '你的登录授权信息已过期或已在其他设备上登录，请重新登录！',
          type: 'warning',
        })
        userStore().logout()
        setTimeout(() => {
          // window.location.href = '/'
          // window.open(loginPath, '_self')
          // window.open(loginPath.host+loginPath.path, '_self')
          // console.log('🎉-----loginPath.host+loginPath.path-----', loginPath.host+loginPath.path);
          const {origin,pathname} = window.location
          window.open(origin+pathname, '_self')
          console.log('🍪-----9.28日测试-----');
          console.log('🐳-----origin+pathname-----', origin+pathname);
        }, 1000)
      }
    }
    return response
  },
  (error) => {
    //响应错误处理
    if (!error.config?.notLoadding) tryHideFullScreenLoading()
    ElMessage({
      message: '服务器开小差啦，请稍后重试！！',
      type: 'error',
    })
    return Promise.reject(error)
  }
)
export default instance
