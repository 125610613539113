import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e137fe40"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-box"
};
const _hoisted_2 = {
  class: "menu"
};
const _hoisted_3 = {
  class: "menu-title"
};
import Head from "@/components/header.vue";
import { ref, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { getLocalStorage, removeLocalStorage } from "@/utils/index";
export default {
  __name: 'layout',
  setup(__props) {
    const router = useRouter();
    const menuList = ref([{
      title: "课题管理",
      path: "/SubjectManagement"
    }, {
      title: "课题学段、学科管理",
      path: "/Category"
    }, {
      title: "局管理员设置",
      path: "/setSubject"
    }]);
    const menuList1 = ref([{
      title: "课题管理",
      path: "/SubjectManagement"
    }, {
      title: "课题学段、学科管理",
      path: "/Category"
    }]);
    const roleCodes = ref('');
    const computedName = computed(() => {
      if (roleCodes.value == 'problem_admin') {
        return menuList1.value;
      } else {
        return menuList.value;
      }
    });
    const activePath = ref("");
    watch(() => router.currentRoute.value, v => {
      activePath.value = v.meta.parent;
    }, {
      immediate: true,
      deep: true
    });
    const selectPath = index => {
      console.log(index);
    };
    onMounted(() => {
      if (getLocalStorage('userInfo')) {
        const {
          name,
          roleCode,
          username
        } = getLocalStorage('userInfo').teacher;
        roleCodes.value = roleCode;
        console.log('🍭-----roleCode-----', roleCode);
        console.log('🐠-----name-----', name);
      }
    });
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Head), _createVNode(_component_el_container, {
        class: "main-con"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedName.value, (item, index) => {
          return _openBlock(), _createBlock(_component_el_menu, {
            "default-active": activePath.value,
            onSelect: selectPath,
            class: "el-menu-vertical-demo",
            key: index,
            router: true
          }, {
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: item.path
            }, {
              default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1)]),
              _: 2
            }, 1032, ["index"])]),
            _: 2
          }, 1032, ["default-active"]);
        }), 128))]), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createVNode(_component_router_view, null, {
            default: _withCtx(({
              Component
            }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.name
            })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.name
            })) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};