import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { ElLoading } from "element-plus";

/** 获取本地存储 */
export const getLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};
/** 设置本地存储 */
export const setLocalStorage = (key, val) => {
  return localStorage.setItem(key, JSON.stringify(val));
};
/** 删除本地存储 */
export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const getSessionStorage = (key) => {
  if (sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key));
  }
};
export const setSessionStorage = (key, val) => {
  return sessionStorage.setItem(key, JSON.stringify(val));
};
export const removeSessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};
// 时间格式化
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    // return '无'
    return "";
    // return null
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else {
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

export const useDateFormat = (time, format) => {
  const date = new Date(Number(time));
  const o = {
    "M+": date.getMonth() + 1, //month
    "D+": date.getDate(), //day
    "h+": date.getHours(), //hour
    "m+": date.getMinutes(), //minute
    "s+": date.getSeconds(), //second
    "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
    S: date.getMilliseconds(), //millisecond
  };
  if (/(Y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return format;
};
// 文件下载
// export function downloadFile(url, fileName = "未知文件") {
//   //
//   // const link = document.createElement('a')
//   // link.style.display = 'none'
//   // link.href = url
//   // link.setAttribute('download', fileName)
//   // document.body.appendChild(link)
//   // link.click()
//   // document.body.removeChild(link)

//   var newUrl = url;
//   if (newUrl.substring(0, 5) === "http:") {
//     newUrl = url.replace("http", "https");
//   }
//   window.open(newUrl, "_self");
// }
// 文件下载
export function downloadFile(url, fileName = "未知文件") {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFileBySaveAs(url, fileName) {
  const loadingInstance = ElLoading.service({
    lock: true,
    text: "下载中...",
    background: "rgba(0, 0, 0, 0.7)",
  });
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
      loadingInstance.close();
    })
    .catch((error) => {
      loadingInstance.close();
    });
}

export const handleTableData = (data = {}, listData = []) => {
  listData.forEach((item) => {
    if (item.key.length > 0) {
      let defaultVal = ["fileList"].includes(item.type) ? [] : "";
      let temp = data;
      item.key.forEach((key) => {
        temp = temp[key] || defaultVal;
      });
      item.value = item.valueFormat ? item.valueFormat(temp) : temp;
    }
  });
  return listData;
};
export const handleListData = (result = {}, listData = []) => {
  const listDataTemp = listData || [];
  listDataTemp.forEach((item) => {
    const { value = [], type, key: listKey } = item;
    if (type == "list") {
      let tempLsit = result;

      listKey.forEach((key) => {
        tempLsit = tempLsit[key] || [];
      });
      const baseItem = { ...value[0] };
      const valueT = tempLsit.map((itemList, index) => {
        const { data = [] } = baseItem;

        const tempData = data.map((itemFile) => {
          const { key: key3, valueFormat } = itemFile;
          // let temp = itemList;
          // key3.forEach((key4) => {
          //   temp = temp[key4] || "";
          // });
          // const valT = valueFormat ? valueFormat(temp) : temp;

          // let temp = [ ...itemList] ;
          const tempArr = [];
          const isOne = itemFile.key.every((item) => !Array.isArray(item));
          if (isOne) {
            itemFile.key = [itemFile.key];
          }
          itemFile.key.forEach((key) => {
            let tmepVal = itemList;
            key.forEach((key2) => {
              tmepVal = tmepVal[key2];
            });
            tempArr.push(tmepVal);
          });
          const formatVal = tempArr.length === 1 ? tempArr[0] : tempArr;
          itemFile.value = itemFile.valueFormat
            ? itemFile.valueFormat(formatVal)
            : formatVal;
          // console.log('🐙-----valT-----', valT);  // text: valT
          return {
            ...itemFile,
            // value: valT,
          };
        });

        return {
          ...baseItem,
          data: tempData,
          subtitle: `课题研究${index + 1}`,
        };
      });
      item.value = valueT;
    } else {
      value.forEach((item2) => {
        const { data = [] } = item2;
        data.forEach((item3) => {
          if (item3.key.length > 0) {
            let defaultVal = ["fileList"].includes(item3.type) ? [] : "";
            let temp = { ...result };
            const tempArr = [];
            const isOne = item3.key.every((item) => !Array.isArray(item));
            if (isOne) {
              item3.key = [item3.key];
            }
            item3.key.forEach((key) => {
              let tmepVal = { ...result };
              key.forEach((key2) => {
                tmepVal = tmepVal[key2] || defaultVal;
              });
              tempArr.push(tmepVal);
            });
            const formatVal = tempArr.length === 1 ? tempArr[0] : tempArr;
            item3.value = item3.valueFormat
              ? item3.valueFormat(formatVal)
              : formatVal;
          }
        });
      });
    }
  });
  //  // listDataTemp
  return listDataTemp;
};
export function downloadFileList(list) {
  if (!list || !list.length) {
    return;
  }
  const loadingInstance = ElLoading.service({
    lock: true,
    text: "下载中...",
    background: "rgba(0, 0, 0, 0.7)",
  });
  const promises = list.map((item) => {
    return fetch(item.url)
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, item.name);
      })
      .catch((error) => { });
  });
  let count = 0;
  const total = list.length;
  Promise.all(
    promises.map((p) =>
      p
        .catch((e) => { })
        .finally(() => {
          count++;
          loadingInstance.text = `下载中...${count}/${total}`;
        })
    )
  ).then(() => {
    loadingInstance.close();
  });
}

// 时间格式化
export const formatTime = (time, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!time) return "";
  if (time.toString().length < 13) {
    time = time * 1000;
  }
  return dayjs(time).format(format);
};
export const getFileType = (url = "") => {
  const flieArr = url ? url.split(".") : "";
  const suffix = flieArr[flieArr.length - 1];
  let type;
  if (suffix) {
    type = suffix.toLowerCase();
  } else {
    return "other";
  }
  if (["png", "jpg", "jpeg", "bmp", "gif", "webp"].includes(type)) {
    return "image";
  } else if (
    ["mp4", "m2v", "mkv", "rmvb", "wmv", "avi", "flv", "mov", "m4v"].includes(
      type
    )
  ) {
    return "video";
  } else if (["mp3", "wav", "wmv"].includes(type)) {
    return "radio";
  } else if (["doc", "docx"].includes(type)) {
    return "doc";
  } else if (["xls", "xlsx"].includes(type)) {
    return "xls";
  } else {
    return "other";
  }
};

export default {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  parseTime,
  downloadFile,
  useDateFormat,
};
