import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout/layout.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login.vue'),
  },
  //中台
  {
    path: '/auth/:type',
    name: 'auth',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth.vue'),
  },
  /* 管理员路由 */
  //课题管理
  {
    path: '/SubjectManagement',
    name: 'SubjectManagement',
    component: layout,
    redirect: '/SubjectManagement/index',
    children: [
      {
        path: '/SubjectManagement/index',
        name: 'SubjectManagementIndex',
        meta: { parent: '/SubjectManagement',keepAlive: true },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/SubjectManagement/index'
          )
      },
      {
        path: '/SubjectManagement/task',
        name: 'SubjectManagementTask',
        meta: { parent: '/SubjectManagement',keepAlive: true },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/SubjectManagement/task'
          )
      },
      {
        path: '/SubjectManagement/detail',
        name: 'SubjectManagementDetail',
        meta: { parent: '/SubjectManagement'},
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/SubjectManagement/detail'
          )
      },
    ],
  },
  //课题类别管理
  {
    path: '/Category',
    name: 'Category',
    component: layout,
    redirect: '/Category/index',
    children: [
      {
        path: '/Category/index',
        name: 'CategoryIndex',
        meta: { parent: '/Category' },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/Category/index'
          )
      },
    ],
  },
  //管理员设置
  {
    path: '/setSubject',
    name: 'setSubject',
    component: layout,
    redirect: '/setSubject/index',
    children: [
      {
        path: '/setSubject/index',
        name: 'setSubjectIndex',
        meta: { parent: '/setSubject' },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/setSubject/index'
          )
      },
    ],
  },
  /* 课题负责人路由 */
  {
    path: '/subjectFacilitator',
    name: 'subjectFacilitator',
    meta: {
      title: "",
      keepAlive: true,
    },
    component: () =>
      import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/subjectFacilitator/index'
      )
  },
  {
    path: '/subjectFacilitator/ProposalApplication',
    name: 'ProposalApplication',
    component: () =>
      import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/subjectFacilitator/ProposalApplication'
      )
  },
  {
    path: '/subjectFacilitator/ProposalReview',
    name: 'ProposalReview',
     meta: {
      title: "",
      keepAlive: false,
    },
    component: () =>
      import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/subjectFacilitator/ProposalReview'
      )
  },
  /* 评分专家路由 */
  {
    path: '/ScoringExperts',
    name: 'ScoringExperts',
    redirect: '/ScoringExperts/index',
    children: [
      {
        path: '/ScoringExperts/index',
        name: 'ScoringExpertsIndex',
        meta: { parent: '/ScoringExperts',keepAlive: true },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/ScoringExperts/index'
          )
      },
      {
        path: '/ScoringExperts/list',
        name: 'ScoringExpertsList',
        meta: { parent: '/ScoringExperts' },
        component: () =>
          import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/views/ScoringExperts/list'
          )

      }
    ],
  },
  /* 课题审核员路由 */
  {
    path: '/GradingExpert',
    name: 'GradingExpert',
    redirect: '/GradingExpert/index',
    children: [
      {
        path: '/GradingExpert/index',
        name: 'GradingExpertIndex',
        meta: { parent: '/GradingExpert',keepAlive: true },
        component: () =>
          import(
              /* webpackChunkName: "UserUpdateInfo" */ '@/views/GradingExpert/index'
          )
      },
      {
        path: '/GradingExpert/list',
        name: 'GradingExpertList',
        meta: { parent: '/GradingExpert' },
        component: () =>
          import(
              /* webpackChunkName: "UserUpdateInfo" */ '@/views/GradingExpert/list'
          )

      }
    ],
  },

  //操作指南路由
  {
    path: '/components/guidePage',
    name: 'guidePage',
    component: () =>
      import(
            /* webpackChunkName: "UserUpdateInfo" */ '@/components/guidePage'
      )
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [...routes]
})

export default router
