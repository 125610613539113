import { defineStore } from 'pinia'
import { ref } from 'vue'
import { adminLogin, adminGetInfo,adminscoreLogin } from '@/request/admin'
import { setToken, removeToken } from '@/utils/auth'
export const userStore = defineStore('user', () => {
  const userInfo = ref(null)
  // 存储用户信息
  function saveUserInfo(data) {
    userInfo.value = data
  }
  // 退出登录
  function logout() {
    userInfo.value = {}
    removeToken()
  }
  /** 登入 */
  function login(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      adminscoreLogin(data)
        .then(({ data }) => {
          const { code, result } = data
          if (code === 0 && result) {
            console.log(result);
            const {
              teacher = {},
              // admin: { username, adminType } = {},
              teacher: {username,workName,roles} = {},
              token: { access_token, refresh_token, expires_in, scope, token_type } = {},
            } = result || {}
            console.log(access_token,refresh_token);
            const setParams = {
              username: username,
              roles: roles,
              // adminType: adminType,
              accessToken: access_token,
              refreshToken: refresh_token,
              expires: new Date().getTime() + expires_in * 1000,
            }
            console.log('setParams', setParams)
            // 保存用户信息
            saveUserInfo(teacher)
            setToken(setParams)
            resolve(data)
          } else {
            resolve(data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  return {
    userInfo,
    saveUserInfo,
    logout,
    login
  }
},
  {
    persist: {
      key: 'userInfo-key',
    },
  }
)