import axios from '@/utils/http/http.js'
import baseUrl from '@/utils/http/base.js'
/** 新增后台用户 */
export const adminAdd = (params) => {
  return axios.post(`/back/admin/save`, params)
}

/**
 * code登录
 */
console.log(baseUrl.apiServer);
export const codeLogin = (params) => {
  return axios.post(`${baseUrl.apiServer}/common/teacher/v2/loginByCode`, params, {
    isNeedToken: false,
  })
};

/** 生成审核员 */
export const addGenerateAuditor = (params) => {
  return axios.post(`${baseUrl.apiServer}/back/admin/generateAuditor`, params)
}

/** 后台用户更新 */
export const adminUpdate = (params) => {
  return axios.post(`/back/admin/update`, params)
}

/** 根据ID删除后台用户 */
export const adminDelete = (params) => {
  return axios.post(`/back/admin/delete`, params)
}

/** 后台用户登录 */
export const adminLogin = (params) => {
  return axios.post(`${baseUrl.apiServer}/teacher/login`, params, {
    isNeedToken: false,
  })
}
/** 后台评分专家用户登录 */
export const adminscoreLogin = (params) => {
  return axios.post(`${baseUrl.apiServer}/scoringExpert/login`, params, {
    isNeedToken: false,
  })
}
/** 条件查询后台用户 */
export const adminFindAll = (params) => {
  return axios.get(`/back/admin/findAll`, {
    params: params,
  })
}
/** 获取管理员信息 */
export const adminGetInfo = (params) => {
  return axios.get(`/back/admin/getAdminInfo`, {
    params: params,
  })
}

/** 修改密码 */
export const adminUpdatePassword = (params) => {
  return axios.post('/back/admin/updatePassword', params)
}

/** 管理员相关接口 --查询 */
export const AdminFindAll = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/findAll`, {
    params: params,
  })
}
/** 管理员相关接口 --新增 */
export const AdminSave = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/save`, params)
}
/** 管理员相关接口 --id停用或启用 */
export const Enabled = (params) => {
  return axios.post(`${baseUrl.apiServer}/common/teacher/enabled`, params)
}
/** 管理员相关接口 --id停用或启用V2 */
export const EnabledV2 = (params) => {
  return axios.post(`${baseUrl.apiServer}/common/teacher/enabledV2`, params)
}
/** 管理员相关接口 --批量导出管理员 */
export const adminExport = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/export`, {
    params: params,
  })
}
/** 管理员相关接口 --批量导入管理员 */
export const adminimportAdmin = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/importAdmin`, params)
}

/** 学校管理员相关接口 --分页查询分组 */
export const schoolFindGrouping = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/school/findGroupingAll`, {
    params: params,
  })
}
/** 学校管理员相关接口 --分页查询 */
export const schoolFindAll = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/school/findAll`, {
    params: params,
  })
}
/** 学校管理员相关接口 --新增 */
export const schoolSave = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/school/save`, params)
}
/** 学校管理员相关接口 --新增分组 */
export const saveGroup = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/school/saveGroup`, params)
}

/** 学校管理员相关接口 --导入名单*/
export const importSchoolAdmin = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/school/importList`, params)
}
/** 学校管理员相关接口 --获取导入名单*/
export const getImportList = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/school/getImportList`, {
    params: params,
  })
}

/** 学校管理员相关接口 --根据分组导出学校管理员*/
export const exportSchoolAdminByGroup = (params) => {
  return axios.get(`${baseUrl.apiServer}/admin/school/exportSchoolAdminByGroup`, {
    params: params,
  })
}
/** 学校管理员相关接口 --根据分组禁用或启用学校管理员 */
export const freezeByGroupId = (params) => {
  return axios.post(`${baseUrl.apiServer}/admin/school/freezeByGroupId`, params)
}
/** 学校管理员相关接口 --根据分组删除学校管理员 */
export const teacherDelete = (params) => {
  return axios.post(`${baseUrl.apiServer}/common/teacher/delete`, params)
}
