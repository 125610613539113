// 角色枚举
export const Role = {
  ManagerMan: "管理员",
  AuditMan: "课题审核员",
  GradingExpert: "课题评委",
  SubjectFacilitator: "课题负责人",
};
// 课题组别周期枚举
export const TOPIC_GROUP_ENUM = {
  ONE_PERIOD: "一年期",
  TWO_PERIOD: "二年期",
  THREE_PERIOD: "三年期",
  FOUR_PERIOD: "四年期",
  FIVE_PERIOD: "五年期",
  SIX_PERIOD: "六年期",
};
export const Role1 = {
  //未开始
  problem_admin: "管理员",
  //进行中
  problem_head: "课题负责人",
  //已结束
  problem_auditor: "课题审核员",
  activity_scoring_expert: "课题评委",
  super_problem_admin: "超级管理员"
};
export const TIME_SLST = {
  //四月
  ALL: "无限制",
  //十一月
  APRIL_AND_NOVEMBER: '限制在四月份、十一月份上传过程性报告'
};
export const ctivityStates = {
  //未开始
  Not_started: "活动未开始",
  //进行中
  In_progress: "活动进行中",
  //已结束
  Ended: "活动已结束",
};
export const ENUM_PHASESTATE = {
  PROPOSAL: {
    label: "开题阶段",
    color: "#de9709",
    bgColor: "#fff8d5",
    val: "PROPOSAL",
  },
  PROCEDURAL_STAGE: {
    label: "过程性报告",
    color: "#de9709",
    bgColor: "#fff8d5",
    val: "PROPOSAL",
  },
  CONCLUSION: {
    label: "结题阶段",
    color: "#05be30",
    bgColor: "#d5ffdb",
    val: "CONCLUSION",
  },
  EXTENSION: {
    label: "延期审核",
    color: "#be1205",
    bgColor: "#ffdcd5",
    val: "EXTENSION",
  },
  RATING: {
    label: "评分阶段",
    color: "#be1205",
    bgColor: "#ffdcd5",
    val: "RATING",
  },
  FINISH: {
    label: "结束",
    color: "#be1205",
    bgColor: "#ffdcd5",
    val: "FINISH",
  },
};
export const ENUM_PHASESTATE2 = {
  PROPOSAL: {
    label: "开题阶段",
    color: "#de9709",
    bgColor: "#fff8d5",
    val: "PROPOSAL",
  },

  CONCLUSION: {
    label: "结题阶段",
    color: "#05be30",
    bgColor: "#d5ffdb",
    val: "CONCLUSION",
  },

  // EXTENSION: {
  //   label: "延期阶段",
  //   color: "#be1205",
  //   bgColor: "#ffdcd5",
  //   val: "EXTENSION",
  // },
};
export const ENUM_AUDIT = {
  WAIT_AUDIT: {
    label: "待审核",
    color: "#de9709",
    bgColor: "#fff8d5",
  },
  PASS_AUDIT: {
    label: "通过",
    color: "#05be30",
    bgColor: "#d5ffdb",
  },
  SCHOOL_FAIL_AUDIT: {
    label: "不通过",
    color: "#be1205",
    bgColor: "#ffdcd5",
  },
};

export const ADD_S = {
  //待审核
WAIT_AUDIT : '待审核',
//审核通过
PASS_AUDIT : '通过',
//审核不通过
SCHOOL_FAIL_AUDIT : '不通过'
}

export default {
  Role,
  TOPIC_GROUP_ENUM,
  Role1,
  ctivityStates,
  TIME_SLST,
  ADD_S
};
