import Cookies from "js-cookie";
import { getSessionStorage, setSessionStorage } from "@/utils";

// import { useUserStore } from '@/store/user'

export const sessionKeyBase = "user-info";
export const TokenKeyBase = "authorized-token";

/** 获取`token` */
export function getToken(role = "") {
  const TokenKey = role ? `${role}-${TokenKeyBase}` : TokenKeyBase;
  const sessionKey = role ? `${role}-${sessionKeyBase}` : sessionKeyBase;
  // console.log('TokenKey',TokenKey)
  return Cookies.get(TokenKey)
    ? JSON.parse(Cookies.get(TokenKey))
    : getSessionStorage(sessionKey);
}

/** 设置`token`以及key值为`user-info`的session信息 */
export function setToken(data = {}, role = "") {
  const TokenKey = role ? `${role}-${TokenKeyBase}` : TokenKeyBase;
  console.log("setToken", data);
  const { accessToken, refreshToken, expires } = data || {};
  // const expires = expires ? new Date().getTime() + expires * 1000 : 0
  const expiresT = expires ? expires : 0;

  const cookieString = JSON.stringify({ accessToken, expiresT });

  expiresT > 0
    ? Cookies.set(TokenKey, cookieString, {
        expires: expiresT / 86400000,
      })
    : Cookies.set(TokenKey, cookieString);
}

/** 删除`token`以及key值为`user-info`的session信息 */
export function removeToken() {
  Cookies.remove(TokenKeyBase);
  sessionStorage.clear();
}

/** 格式化token（jwt格式） */
export const formatToken = (token) => {
  return "Bearer " + token;
};
/** 获取诸暨中台登录地址 */
// export const getAuthUrl = (type) => {

//   let client_id = process.env.VUE_DSL_CLIENTID ? process.env.VUE_DSL_CLIENTID : "1686712036882";
//   let account_center_url = process.env.VUE_DSL_ACCOUNT_CENTER_URL ? process.env.VUE_DSL_ACCOUNT_CENTER_URL : "https://account-center-test.readboy.com";
//   let redirect_uri = `${window.location.origin}${window.location.pathname}#/auth/${type}`;
//   redirect_uri = encodeURIComponent(redirect_uri);
//   let url = `${account_center_url}/v1/oauth2/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`;

//   return url;
// };
const defaultAccountCenterUrl =
  // "http://account-center.c0c444e4b485b4c7f9e690726e810a8b9.cn-shenzhen.alicontainer.com";

  "http://account-center.c0c444e4b485b4c7f9e690726e810a8b9.cn-shenzhen.alicontainer.com/account-center-api";
/** 获取诸暨中台登录地址 */
export const getAuthUrl = (type) => {
  let client_id = process.env.VUE_APP_DSL_CLIENTID
    ? process.env.VUE_APP_DSL_CLIENTID
    : "1686712036882";
  let account_center_url = process.env.VUE_APP_DSL_ACCOUNT_CENTER_URL
    ? process.env.VUE_APP_DSL_ACCOUNT_CENTER_URL
    : defaultAccountCenterUrl;
  let redirect_uri = `${window.location.origin}${window.location.pathname}#/auth/${type}`;
  redirect_uri = encodeURIComponent(redirect_uri);
  let url = `${account_center_url}/v1/oauth2/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`;
  return url;
};
/**
 * 获取退出登录地址
 */
export const getLogoutUrl = () => {
  let account_center_url = process.env.VUE_APP_DSL_ACCOUNT_CENTER_URL
    ? process.env.VUE_APP_DSL_ACCOUNT_CENTER_URL
    : defaultAccountCenterUrl;
  let redirect_uri = `${window.location.origin}${window.location.pathname}#/`;
  redirect_uri = encodeURIComponent(redirect_uri);
  let url = `${account_center_url}/v1/sso/logout?redirect_uri=${redirect_uri}`;

  return url;
};
